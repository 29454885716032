var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "내부심사 체크리스트 시정조치 목록",
            columns: _vm.grid.columns,
            data: _vm.gridData,
            gridHeight: _vm.gridHeight,
            merge: _vm.grid.merge,
            editable: false,
            isExcelDown: false,
            filtering: false,
            columnSetting: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                    ? _vm._l(
                        _vm.$comm.ibmTagItems(props.row),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                outline: "",
                                square: "",
                                color: item.color,
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.linkClick(item, props.row)
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(item.title) + " "),
                              _c("c-assess-tooltip", {
                                attrs: {
                                  ibmTooltip: item.ibmTooltip,
                                  ibmClassCd: item.ibmClassCd,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    : void 0,
                  _vm.editable &&
                  Boolean(props.row.saiInternalActionChecklistResultId) &&
                  props.row.editFlag !== "C" &&
                  !_vm.disabled
                    ? _c(
                        "div",
                        [
                          _c(
                            "q-btn-group",
                            {
                              staticClass: "ColumInnerBtnGroup",
                              attrs: { outline: "" },
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass: "ColumInnerBtn",
                                  attrs: {
                                    icon: "add",
                                    color: "red-6",
                                    "text-color": "white",
                                    align: "center",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.innerBtnClicked(col, props)
                                    },
                                  },
                                },
                                [_c("q-tooltip", [_vm._v(" 개선요청등록 ")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "suffixTitle" },
            [
              _c("q-radio", {
                staticClass: "text-primary",
                attrs: {
                  size: "sm",
                  "checked-icon": "task_alt",
                  "unchecked-icon": "panorama_fish_eye",
                  dense: "",
                  "keep-color": "",
                  color: "primary",
                  val: false,
                  label: "전체보기",
                },
                model: {
                  value: _vm.check,
                  callback: function ($$v) {
                    _vm.check = $$v
                  },
                  expression: "check",
                },
              }),
              _c("q-radio", {
                staticClass: "text-red",
                attrs: {
                  size: "sm",
                  "checked-icon": "task_alt",
                  "unchecked-icon": "panorama_fish_eye",
                  dense: "",
                  "keep-color": "",
                  color: "red",
                  val: true,
                  label: "부적합/관찰사항 보기",
                },
                model: {
                  value: _vm.check,
                  callback: function ($$v) {
                    _vm.check = $$v
                  },
                  expression: "check",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }